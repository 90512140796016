import 'date-fns';
import React, { PureComponent } from 'react';
import DateFnsUtils from '@date-io/date-fns';

import { DateTimeVariableModel } from '../variable';
import { toVariablePayload } from '../state/types';
import { dispatch } from '../../../store/store';
import { variableAdapters } from '../adapters';
import { changeVariableProp } from '../state/sharedReducer';
import { VariablePickerProps } from '../pickers/types';
import { TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#112266',
    },
  },
});

export interface Props extends VariablePickerProps<DateTimeVariableModel> {}
export interface State {
  value: Date;
}
export class DateTimeVariablePicker extends PureComponent<Props, State> {
  state: State = {
    value: new Date(),
  };

  constructor(props: Props) {
    super(props);
    this.state.value = new Date(parseInt(props.variable.query, 10));
  }

  onChange = async (_date: Date | null) => {
    const date = _date.valueOf().toString();
    await dispatch(changeVariableProp(toVariablePayload(this.props.variable, { propName: 'query', propValue: date })));
    if (this.props.variable.current.value !== this.props.variable.query) {
      variableAdapters.get(this.props.variable.type).updateOptions(this.props.variable);
    }
    this.setState({ value: _date });
  };

  render() {
    return (
      <MuiThemeProvider theme={muiTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            autoOk
            size="small"
            ampm={false}
            disableToolbar
            variant="dialog"
            color="secondary"
            inputVariant="outlined"
            format="yyyy/MM/dd HH:mm"
            onChange={this.onChange}
            value={this.state.value}
            TextFieldComponent={props => <TextField {...props} className="max-width" />}
          />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}
