import React, { PureComponent } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { TimeZone, TimeRange, TimeOption } from '@grafana/data';
import WeekRangeComponent from '../CustomRangeComponents/WeekRangeComponent';
import ShiftRangeComponent from '../CustomRangeComponents/ShiftRangeComponent';
import MonthRangeComponent from '../CustomRangeComponents/MonthRangeComponent';
import QuarterRangeComponent from '../CustomRangeComponents/QuarterRangeComponent';
import TimeRangeComponent from '../CustomRangeComponents/TimeRangeComponent';
import PresetsRangeComponent from '../CustomRangeComponents/PresetsRangeComponent';
import RecentRangeComponent from '../CustomRangeComponents/RecentRangeComponent';
import DayRangeComponent from '../CustomRangeComponents/DayRangeComponent';
import YearRangeComponent from '../CustomRangeComponents/YearRangeComponent';
import AdvancedComponent from '../CustomRangeComponents/AdvancedComponent';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#112266',
    },
    secondary: {
      main: '#112266',
    },
  },
});

interface State {
  rangeType?: any;
}
interface Props {
  value: TimeRange;
  onApply: (timeRange: TimeRange) => void;
  timeZone?: TimeZone;
  quickOptions?: TimeOption[];
  otherOptions?: TimeOption[];
  history?: any;
}
export default class TimeSelectComponent extends PureComponent<Props, State> {
  rangeTypes = [
    { label: 'Shift', value: 'shift' },
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
    { label: 'Quarter', value: 'quarter' },
    { label: 'Year', value: 'year' },
    { label: 'Range', value: 'range' },
    { label: 'Presets', value: 'presets' },
    { label: 'Recent', value: 'recent' },
    { label: 'Advanced', value: 'advanced' },
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      rangeType: this.props.value.raw.range_type || 'shift',
    };
    this.changeRangeType = this.changeRangeType.bind(this);
  }

  changeRangeType = (event: any) => {
    if (event) {
      this.setState({
        rangeType: event.target.value,
      });
    }
  };

  renderOptions() {
    return this.rangeTypes.map((type, i) => {
      return (
        <MenuItem key={i} value={type.value}>
          {type.label}
        </MenuItem>
      );
    });
  }

  renderTypeComponent() {
    const { rangeType } = this.state;
    const { value, timeZone, onApply, quickOptions, otherOptions, history } = this.props;
    value.range_type = rangeType;
    switch (rangeType) {
      case 'shift':
        return <ShiftRangeComponent value={value} timeZone={timeZone} onApply={onApply} />;
      case 'day':
        return <DayRangeComponent value={value} timeZone={timeZone} onApply={onApply} />;
      case 'week':
        return <WeekRangeComponent value={value} timeZone={timeZone} onApply={onApply} />;
      case 'month':
        return <MonthRangeComponent value={value} timeZone={timeZone} onApply={onApply} />;
      case 'year':
        return <YearRangeComponent value={value} timeZone={timeZone} onApply={onApply} />;
      case 'quarter':
        return <QuarterRangeComponent value={value} timeZone={timeZone} onApply={onApply} />;
      case 'range':
        return <TimeRangeComponent value={value} timeZone={timeZone} onApply={onApply} />;
      case 'presets':
        return (
          <PresetsRangeComponent
            value={value}
            quickOptions={quickOptions}
            otherOptions={otherOptions}
            onApply={onApply}
            timeZone={timeZone}
          />
        );
      case 'recent':
        return <RecentRangeComponent value={value} timeZone={timeZone} historyOptions={history} onApply={onApply} />;
      case 'advanced':
        return <AdvancedComponent value={value} timeZone={timeZone} onApply={onApply} />;
      default:
        return null;
    }
  }

  render() {
    const { rangeType } = this.state;
    return (
      <>
        <MuiThemeProvider theme={theme}>
          <Select
            className="max-width timepicker-mat-select"
            value={rangeType}
            variant="outlined"
            onChange={event => this.changeRangeType(event)}
          >
            {this.renderOptions()}
          </Select>
          {this.renderTypeComponent()}
        </MuiThemeProvider>
      </>
    );
  }
}
