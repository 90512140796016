import React from 'react';
import { PureComponent } from 'react';

export class LoginHeader extends PureComponent<any> {
  render() {
    return (
      <div className="login-navbar">
        <a href="/" className="login__logo" key="logo">
          <img className="login__logo_lg" src="public/img/svg_icons/tvarit-logo-full-color.png" alt="Tvarit" />
        </a>
        <div className="navbar__spacer" />
        <div className="login__navbar__buttons">
          <button className="btn login__nav_btn" onClick={this.props.showLogin}>
            Sign In
          </button>
          <button className="btn login__nav_btn" onClick={this.props.showRegister}>
            Register
          </button>
        </div>
      </div>
    );
  }
}
